export const defaultLocale = 'en-US';

export interface LocaleEntry {
  default?: Record<string, string>;
}

const locales = {
  'en-US': async (): Promise<LocaleEntry> => import('./en-US.json'),
  'fr-FR': async (): Promise<LocaleEntry> => import('./fr-FR.json')
};

export default locales;
