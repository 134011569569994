/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@cvent/carina/components/Button';
import { Col } from '@cvent/carina/components/Col';
import Modal from '@cvent/carina/components/Modal';
import {
  Divider,
  GlobalNavigationLink,
  Logo,
  PageTitle,
  ProductTitle,
  TitleBar,
  TopNavigation as TopNav
} from '@cvent/carina/components/Navigation';
import { OnWhen } from '@cvent/carina/components/OnWhen';
import { Row } from '@cvent/carina/components/Row';
import { MenuIcon } from '@cvent/carina/components/Icon';

import NextLink from 'next/link';
import { useState } from 'react';
import { useTranslate } from 'nucleus-text';

interface GlobalTopNavigationProps {
  navigationItems: any[];
  page: any;
  title: any;
  logo: any;
}

export function GlobalTopNavigation({ navigationItems, page, title, logo }: GlobalTopNavigationProps): JSX.Element {
  const { translate } = useTranslate();
  const [isOpen, setIsOpen] = useState(false);

  const simplifyTitle = (titleInput: string): string => titleInput.replace(/\s/g, '-').toLowerCase();

  const getNavItems = () => (
    <>
      {navigationItems.map(
        item =>
          item.items.length === 0 && (
            <GlobalNavigationLink key={item.title} testID={`global-nav-${simplifyTitle(item.title)}`}>
              <NextLink href={item.url.href} passHref>
                <a href={item.url.href}>{translate((item as unknown as { titleKey: string }).titleKey)}</a>
              </NextLink>
            </GlobalNavigationLink>
          )
      )}
    </>
  );

  return (
    <>
      <TopNav>
        <Modal
          aria-label="nav-modal"
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          css={{ maxWidth: '20rem' }}
          zIndex={100}
        >
          <div style={{ margin: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 css={{ marginLeft: '1rem' }}>{translate('navigation_title')}</h2>
              <Button text="Close" onClick={() => setIsOpen(false)} testID="top-navigation-close" />
            </div>
            <ul
              role="menu"
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {getNavItems()}
            </ul>
          </div>
        </Modal>
        <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: '70%'
            }}
          >
            <Logo
              logo={logo}
              // eslint-disable-next-line react/no-unstable-nested-components
              Link={({ item, children }): JSX.Element => (
                <NextLink href={item.url} passHref>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a>{children}</a>
                </NextLink>
              )}
            />
            <OnWhen m l xl>
              <Divider />
              <ProductTitle title={title} />
            </OnWhen>
          </div>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <OnWhen s m>
              <div css={{ marginRight: '1rem', marginLeft: '1rem' }}>
                <Button
                  appearance="ghost"
                  icon={MenuIcon}
                  onClick={() => setIsOpen(true)}
                  testID="top-navigation-open-menu"
                />
              </div>
            </OnWhen>
            <OnWhen l xl>
              <ul
                role="menu"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                {getNavItems()}
              </ul>
            </OnWhen>
          </div>
        </div>
      </TopNav>
      <TitleBar>
        <Row>
          <Col
            width="fill"
            flex={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            <PageTitle title={page} />
          </Col>
        </Row>
      </TitleBar>
    </>
  );
}
