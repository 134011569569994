import { Component, ReactNode } from 'react';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import { getBasePath } from '@cvent/nextjs';
import { v4 as uuid } from 'uuid';

const LOG = LoggerFactory.create('ErrorBoundary');

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

/**
 * A component that catches JavaScript errors that occur in child components, logs the error and
 * redirects to the error page
 *
 * See https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = { hasError: false };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error): void {
    LOG.error(error);
    setTimeout(() => {
      window.location.href = `${getBasePath()}/_error?httpLogPageLoadId=${uuid()}&date=${new Date().toUTCString()}`;
    }, 500);
  }

  public render(): ReactNode {
    const { hasError } = this.state;
    if (hasError) {
      return '';
    }
    const { children } = this.props;
    return children;
  }
}
