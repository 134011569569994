import {
  ApolloClientOptions,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { useMemo } from 'react';
import { createAuthLink } from '@cvent/apollo-client';
import { clientVersion, darkModeVar, localeVar } from '@pages/_app';

const attendeeHubBuilderLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'include'
});

const terminatingLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'include'
});

export function useApolloClientOptions(): ApolloClientOptions<NormalizedCacheObject> {
  return useMemo(() => {
    return {
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              clientVersion: {
                read(): string {
                  return clientVersion();
                }
              },
              darkMode: {
                read(): boolean {
                  return darkModeVar();
                }
              },
              locale: {
                read(): string {
                  return localeVar();
                }
              }
            }
          }
        }
      }),
      link: from([
        createAuthLink(),
        ApolloLink.split(operation => operation.getContext().clientName === 'rfid-admin-tool', attendeeHubBuilderLink),
        terminatingLink
      ])
    };
  }, []);
}
