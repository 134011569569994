import React, { ReactElement, useContext, useState } from 'react';
import type { ReactNode } from 'react';
import { AppAlert } from '@cvent/carina/components/Alert';

interface AlertContextProps {
  showAlert: (message: string) => void;
}

const AlertContext = React.createContext<AlertContextProps>(undefined);

export function AlertProvider({ children }: { children: ReactNode }): ReactElement {
  const [alertMessage, setAlertMessage] = useState(null);

  const showAlert = (message: string) => {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage(null);
    }, 3000);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AlertContext.Provider value={{ showAlert }}>
      {alertMessage && (
        <div style={{ position: 'fixed', zIndex: 120, top: 0, left: 0, right: 0, height: '3rem' }}>
          <AppAlert
            id="0"
            appearance="info"
            content={alertMessage}
            dismissible
            onDismiss={() => setAlertMessage(null)}
          />
        </div>
      )}
      {children}
    </AlertContext.Provider>
  );
}

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};
