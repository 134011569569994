/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-empty-function */
import Button from '@cvent/carina/components/Button';
import { Col } from '@cvent/carina/components/Col';
import Modal from '@cvent/carina/components/Modal';
import { ButtonGroup } from '@cvent/carina/components/ButtonGroup';
import {
  Divider,
  GlobalNavigationLink,
  Logo,
  PageTitle,
  ProductTitle,
  TitleBar,
  TopNavigation as TopNav
} from '@cvent/carina/components/Navigation';
import { OnWhen } from '@cvent/carina/components/OnWhen';
import { Row } from '@cvent/carina/components/Row';
import { MenuIcon, SubQuestionIcon } from '@cvent/carina/components/Icon';

import NextLink from 'next/link';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslate } from 'nucleus-text';
import { usePagePathParams } from '@tools/usePageQueryParams';
import { useQuery } from '@apollo/client';
import { getFullEventInfoQuery } from '@cvent/rfid-admin-tool-model/operations/event';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import LoadingSpinner from '@cvent/carina/components/LoadingSpinner';
import getConfig from 'next/config';

const LOG = LoggerFactory.create('Event-List');

interface GlobalTopNavigationProps {
  navigationItems: any[];
  page: any;
  title: any;
  logo: any;
  overrideContextToggle?: number;
}

export function EventContextTopNavigation({
  navigationItems,
  page,
  title,
  logo,
  overrideContextToggle
}: GlobalTopNavigationProps): JSX.Element {
  // 0 = Attendance | Exhibitor = 1
  let contextToggle = 0;
  let exhibitorEnabled = false;
  const { eventID: eventId } = usePagePathParams();
  const router = useRouter();
  const { translate } = useTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const { publicRuntimeConfig } = getConfig();

  if (overrideContextToggle === undefined) {
    contextToggle = page.includes('attendance') ? 0 : 1;
  } else {
    contextToggle = overrideContextToggle;
  }

  const navigationItemsUnpacked = navigationItems[0]?.items;
  const currentNavItem = navigationItemsUnpacked[contextToggle]?.items?.find(item => item.url.href === page);

  const simplifyTitle = (titleInput: string): string => titleInput.replace(/\s/g, '-').toLowerCase();
  const removeDoubleHyphens = (input: string): string => input.replace(/--+/g, '-');

  const getNavItems = () =>
    navigationItemsUnpacked[contextToggle]?.items?.map(
      (item: { items: unknown; title: string; url: { href: string } }) => (
        <GlobalNavigationLink key={item.title} testID={removeDoubleHyphens(`local-nav-${simplifyTitle(item.title)}`)}>
          <NextLink href={item.url.href} passHref>
            <a href={item.url.href} onClick={() => setIsOpen(false)}>
              {translate((item as unknown as { titleKey: string }).titleKey)}
            </a>
          </NextLink>
        </GlobalNavigationLink>
      )
    );

  const getRedirectSubPath = (contextPath: string): string => {
    if (
      currentNavItem.titleKey === 'page_title_event_locations' ||
      currentNavItem.titleKey === 'page_title_event_clear_location_requests' ||
      currentNavItem.titleKey === 'page_title_event_stations'
    ) {
      return `/events/${eventId}/${contextPath}/${simplifyTitle(translate('page_title_event_monitor'))}`;
    }
    return `/events/${eventId}/${contextPath}/${simplifyTitle(
      translate((currentNavItem as unknown as { titleKey: string }).titleKey)
    )}`;
  };

  const {
    loading: loadingFullEvent,
    error: errorFullEvent,
    data: dataFullEvent
  } = useQuery(getFullEventInfoQuery, {
    variables: {
      id: eventId,
      searchEnv: publicRuntimeConfig.ENVIRONMENT === 'dev' ? 'S606' : publicRuntimeConfig.ENVIRONMENT
    },
    notifyOnNetworkStatusChange: true
  });

  if (errorFullEvent) {
    LOG.error('Failed to load event', errorFullEvent);
  } else if (!loadingFullEvent) {
    exhibitorEnabled =
      !!dataFullEvent.fullEventInfo.additionalEventContext.exhibitorAccountId &&
      !!dataFullEvent.fullEventInfo.additionalEventContext.exhibitorEventId;
  }

  return (
    <>
      <TopNav css={{ height: '20rem' }}>
        <Modal
          aria-label="nav-modal"
          isOpen={isOpen}
          onDismiss={() => setIsOpen(false)}
          css={{ maxWidth: '20rem' }}
          zIndex={100}
        >
          <div style={{ margin: '1rem' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2 css={{ marginLeft: '1rem' }}>{translate('navigation_title')}</h2>
              <Button text="Close" onClick={() => setIsOpen(false)} testID="top-navigation-close" />
            </div>
            <ul
              role="menu"
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              {getNavItems()}
            </ul>
          </div>
        </Modal>
        {loadingFullEvent && <LoadingSpinner testID="custom-navigation-spinner" />}
        {!loadingFullEvent && !errorFullEvent && (
          <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                maxWidth: '70%'
              }}
            >
              <Logo
                logo={logo}
                // eslint-disable-next-line react/no-unstable-nested-components
                Link={({ item, children }): JSX.Element => (
                  <NextLink href={item.url} passHref>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a>{children}</a>
                  </NextLink>
                )}
              />
              <OnWhen m l xl>
                <Divider />
                <ProductTitle title={title} />
                <div css={{ paddingLeft: '1rem' }}>
                  <Divider />
                </div>
              </OnWhen>
              {exhibitorEnabled && (
                <div css={{ paddingLeft: '1rem' }}>
                  <ButtonGroup onChange={() => {}} defaultSelected={0} selected={contextToggle}>
                    <ButtonGroup.Item
                      testID="attendance-switch-button"
                      text={translate('button_context_switch_attendance')}
                      onClick={() => router.push(getRedirectSubPath('attendance'))}
                    />
                    <ButtonGroup.Item
                      testID="exhibitor-switch-button"
                      text={translate('button_context_switch_exhibitor')}
                      onClick={() => router.push(getRedirectSubPath('exhibitor'))}
                    />
                  </ButtonGroup>
                </div>
              )}
            </div>
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <OnWhen s m>
                <div css={{ marginRight: '1rem', marginLeft: '1rem' }}>
                  <Button
                    appearance="ghost"
                    icon={MenuIcon}
                    onClick={() => setIsOpen(true)}
                    testID="top-navigation-open-menu"
                  />
                </div>
              </OnWhen>
              <OnWhen l xl>
                <ul
                  role="menu"
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  {getNavItems()}
                </ul>
              </OnWhen>
              <OnWhen xl>
                <Button
                  icon={SubQuestionIcon}
                  appearance="primary"
                  text={translate('change_event_button')}
                  size="l"
                  css={{ minWidth: 'auto', paddingLeft: '1rem', paddingRight: '1rem', marginRight: '1rem' }}
                  onClick={() => router.push('/events')}
                  testID="event-context-back-button"
                />
              </OnWhen>
              <OnWhen s m l>
                <Button
                  icon={SubQuestionIcon}
                  appearance="primary"
                  text={`${translate('event_context_back_shortened_button')}`}
                  size="l"
                  css={{ minWidth: 'auto', paddingLeft: '1rem', paddingRight: '1rem', marginRight: '1rem' }}
                  onClick={() => router.push('/events')}
                  testID="event-context-back-shortened-button"
                />
              </OnWhen>
            </div>
          </div>
        )}
      </TopNav>
      <TitleBar>
        <Row>
          <Col
            width="fill"
            flex={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start'
            }}
          >
            {currentNavItem && (
              <PageTitle
                testID="nav-page-title"
                title={translate((currentNavItem as unknown as { titleKey: string }).titleKey)}
              />
            )}
          </Col>
        </Row>
      </TitleBar>
    </>
  );
}
