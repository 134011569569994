import React, { ReactElement } from 'react';
import type { ReactNode } from 'react';
import { Page as TemplatePage, ContentArea, Main, Body, Header } from '@cvent/carina/components/Templates';
import { TopNavigation } from './TopNavigation';
import { AlertProvider } from './AlertProvider';

export function BasePage({ children }: { children: ReactNode }): ReactElement {
  return (
    <TemplatePage>
      <Main as="div">
        <AlertProvider>
          <div style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
            <Header>
              <TopNavigation />
            </Header>
          </div>
          <Body>
            <ContentArea>
              <div
                css={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  paddingTop: '7rem'
                }}
              >
                {children}
              </div>
            </ContentArea>
          </Body>
        </AlertProvider>
      </Main>
    </TemplatePage>
  );
}
