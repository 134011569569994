/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'next/router';

/**
 * Hook to extract the query params for a page request if present
 */
export const usePageQueryParams = (): any => {
  const { query = {} } = useRouter();
  return query || {};
};

/**
 * Hook to extract the query params for a page request if present
 */
export const usePagePathParams = (): any => {
  const { query = {}, asPath } = useRouter();

  // Extract the eventID from the URL path
  const pathSegments = asPath.split('/');
  const eventID = pathSegments[2]; // Assuming the eventID is always the third segment

  return { ...query, eventID };
};

export default usePageQueryParams;
