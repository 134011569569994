/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line import/order
import { datadogOptions } from '../config/initBrowserLogging';

// TODO: move this css boilerplate into a next.js plugin
import '@cvent/carina/fonts/fonts.web.css';
import 'normalize.css'; // css reset

import { ApolloProvider, makeVar, useQuery } from '@apollo/client';
import AppProviders from '@components/AppProviders';
import ErrorBoundary from '@components/ErrorBoundary';
import DatadogInit from '@components/datadog-init';
import { ApolloClientFactory, useApollo } from '@cvent/apollo-client';
import { LoadingSpinner } from '@cvent/carina/components/LoadingSpinner';
import { NavigationProvider } from '@cvent/carina/components/Navigation';
import { ThemeProvider } from '@cvent/carina/components/ThemeProvider';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import { Link, getItem } from '@cvent/nextjs';
import { initRum } from '@cvent/nextjs/datadog/initRum';
import { GET_APP_NAVIGATION_QUERY } from '@cvent/planner-navigation/client/queries';
import { SessionProvider, signIn, useSession } from 'next-auth/react';
import getConfig from 'next/config';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useApolloClientOptions } from '@server/apolloClient';
import { defaultLocale } from '../../locales';
import { BasePage } from '../components/BasePage';

const { publicRuntimeConfig } = getConfig();
const LOG = LoggerFactory.create('App');

// example of app wide global state, move to another file and import here as this grows!
// to learn more options for local state management go here:
//    - https://www.apollographql.com/blog/local-state-management-with-reactive-variables/
export const darkModeVar = makeVar<boolean>(false);
export const clientVersion = makeVar<string>(String(process.env.CLIENT_VERSION) || 'unknown');
export const localeVar = makeVar<string>(getItem('locale') || defaultLocale);
export const hasInitDatadog = makeVar<boolean>(false);

function Auth({ children }): JSX.Element {
  // See https://next-auth.js.org/getting-started/client#custom-client-session-handling
  // for details on how this is implemented
  const { data: session, status } = useSession();
  const loading = status === 'loading';
  const isUser = !!session?.user;
  React.useEffect(() => {
    if (loading) return; // Do nothing while loading
    if (!isUser) signIn('okta'); // If not authenticated, force log in
  }, [isUser, loading]);

  if (isUser) {
    return children;
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <LoadingSpinner />;
}

Auth.propTypes = {
  children: PropTypes.node
};

export default function App({ Component, pageProps }): JSX.Element {
  initRum(datadogOptions);

  // Get apollo client cache and link options for client side apollo client
  const apolloClientOptions = useApolloClientOptions();

  const client = useApollo(
    pageProps,
    new ApolloClientFactory({
      initialState: {},
      loginUrl: publicRuntimeConfig.LOGIN_URL || 'login',
      errorUrl: '/_error',
      graphBasePath: process.env.NEXT_PUBLIC_BASE_PATH,
      graphUri: process.env.NEXT_PUBLIC_NX_GRAPHQL_URI || 'api/graphql'
    }),
    apolloClientOptions
  );

  const router = useRouter();

  const navMetadata = pageProps?.navMetadata || Component.navMetadata;
  const { loading, data } = useQuery(GET_APP_NAVIGATION_QUERY, {
    client,
    variables: {
      navMetadata: pageProps?.navMetadata || Component.navMetadata
    },
    skip: !navMetadata,
    context: {
      clientName: 'rfid-admin-tool'
    }
  });

  const component = useMemo(() => {
    if (loading) {
      // TODO: empty for now, discuss options with Carina team and UX.
      // implement getStaticProps or getServerSideProps on a page to avoid
      return <div />;
    }

    const handlers = {
      setSearchTerm: (term): void => {
        LOG.info('SEARCH TERM:', term);
      },
      submitFilters: (content): void => {
        LOG.info('FILTERS CONTENT', content);
      },
      onSearch: (term?: string): void => {
        LOG.info('onSearch', term);
        router.push(`/search?term=${encodeURIComponent(term)}`);
      }
    };

    const nav = {
      ...data?.navigation,
      ...handlers,
      Link
    };

    return (
      <ThemeProvider theme={getDefaultTheme()}>
        <DatadogInit />
        <SessionProvider session={pageProps.session}>
          <ApolloProvider client={client}>
            <AppProviders>
              <ErrorBoundary>
                {/* Enable auth by default on every page */}
                <Auth>
                  {navMetadata ? (
                    <>
                      <Head>
                        <title>{data?.navigation.page}</title>
                      </Head>
                      <NavigationProvider {...nav}>
                        <BasePage>
                          <Component {...pageProps} />
                        </BasePage>
                      </NavigationProvider>
                    </>
                  ) : (
                    <Component {...pageProps} />
                  )}
                </Auth>
              </ErrorBoundary>
            </AppProviders>
          </ApolloProvider>
        </SessionProvider>
      </ThemeProvider>
    );
  }, [loading, data?.navigation, client, navMetadata, Component, pageProps, router]);

  return component;
}
