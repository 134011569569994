// Necessary if using App Router to ensure this file runs on the client

'use client';

import getConfig from 'next/config';
import { datadogRum } from '@datadog/browser-rum';

const { publicRuntimeConfig } = getConfig();
datadogRum.init({
  actionNameAttribute: 'data-cvent-id',
  applicationId: publicRuntimeConfig.DD_APP_ID,
  clientToken: publicRuntimeConfig.DD_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: publicRuntimeConfig.DD_SERVICE,
  env: publicRuntimeConfig.DD_ENV,
  version: publicRuntimeConfig.DD_VERSION,
  sessionSampleRate: publicRuntimeConfig.DD_SAMPLE_RATE,
  sessionReplaySampleRate: publicRuntimeConfig.DD_SESSION_REPLAY_SAMPLE_RATE,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow',
  // Specify URLs to propagate trace headers for connection between RUM and backend trace
  allowedTracingUrls: [{ match: 'window.location.origin', propagatorTypes: ['tracecontext'] }]
});

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
